<template>
	<div class="page">
		<fieldset class="search-box">
			<legend>查询条件</legend>
			<el-input size="small" style="width: 300px;margin-right: 20px;" placeholder="请输入卡商名" v-model="input1">
				<template #prepend>卡商名</template>
			</el-input>
			<el-button size="small">搜索</el-button>
			<el-button @click="addDialog = true" type="primary" size="small">添加卡商</el-button>
		</fieldset>

		<div ref="tableBox" style="margin-top: 10px;flex-grow: 1; background-color: white;padding-bottom: 20px;">
			<el-table size="small" header-cell-class-name="tableHeader" :data="listData" border
				:max-height="tableHeight" style="width: 100%;">
				<el-table-column fixed prop="title" label="卡商名称" width="200">
				</el-table-column>
				<el-table-column fixed prop="cardNum" label="卡数量" width="200">
				</el-table-column>
				<el-table-column fixed="right" label="操作">
					<template #default="scope">
						<el-button @click="show(scope.row)" type="text" size="small">查看</el-button>
						<el-button @click="exportXlsx(scope.row)" type="text" size="small">导出卡数据</el-button>
<!-- 						<el-button @click="deleteAllCard(scope.row)" type="text" size="small">删除所有卡数据</el-button>
						<el-button @click="deleteItem(scope.row)" type="text" size="small">删除</el-button> -->
					</template>
				</el-table-column>
			</el-table>

			<div style="margin-top: 20px; text-align: right;">
				<el-pagination background layout="prev, pager, next" :total="1000"></el-pagination>
			</div>
		</div>
		
		<el-dialog v-model="editDialog" width="40%">
			<template #title>
				<span>
					<span>查看编辑</span>
				</span>
			</template>
			
			<el-card>
				<el-form label-position="left" label-width="100px" v-model="editItem">
					<el-form-item label="卡商名称">
						<el-input v-model="editItem.title"></el-input>
					</el-form-item>
					<el-form-item label="key">
						<el-input v-model="editItem.key"></el-input>
					</el-form-item>
					<el-form-item label="secret">
						<el-input v-model="editItem.secret"></el-input>
					</el-form-item>
				</el-form>
			</el-card>
		
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="editDialog = false">取消</el-button>
					<el-button @click="edit" type="primary">修改</el-button>
				</span>
			</template>
		</el-dialog>
		
		<el-dialog v-model="addDialog" width="40%">
			<template #title>
				<span>
					<span>添加卡商</span>
				</span>
			</template>
			
			<el-card>
				<el-form label-position="left" label-width="100px" v-model="addItem">
					<el-form-item label="卡商名称">
						<el-input v-model="addItem.title"></el-input>
					</el-form-item>
					<el-form-item label="key">
						<el-input v-model="addItem.key"></el-input>
					</el-form-item>
					<el-form-item label="secret">
						<el-input v-model="addItem.secret"></el-input>
					</el-form-item>
				</el-form>
			</el-card>
		
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="addDialog = false">取消</el-button>
					<el-button @click="add" type="primary">添加</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {updateKaShangItem, getKaShangList,addKaShangItem,deleteKaShangItem,getKaShangById,deleteCardByKaShang } from '../../api/api.js'
	import {
		ElNotification,
	} from 'element-plus'
	export default {
		data() {
			return {
				title: "",
				addBtn: false,
				editBtn: false,
				nowItem: {},
				listData:[],
				addItem:{},
				addDialog:false,
				editItem:{},
				editDialog:false
			}
		},
		
		mounted() {
			this.list()
		},

		methods: {
			list:function(){
				var that = this
				getKaShangList().then(res=>{
					console.log(res)
					that.listData = res.data.data
				})
			},
			
			exportXlsx:function(data){
				window.open("http://127.0.0.1:8887/ka/export?id="+data.id)
			},
			
			deleteAllCard:function(data){
				deleteCardByKaShang(data.id).then(()=>{
					ElNotification({
						title: '成功',
						message: '删除成功！',
						type: 'success',
					})
				})
			},
			
			add:function(){
				if(!this.addItem.title){
					ElNotification({
						title: '提示',
						message: '缺少商户名称！',
						type: 'error',
					})
					return
				}
				if(!this.addItem.key){
					ElNotification({
						title: '提示',
						message: '缺少商户key！',
						type: 'error',
					})
					return
				}
				if(!this.addItem.secret){
					ElNotification({
						title: '提示',
						message: '缺少商户sercret！',
						type: 'error',
					})
					return
				}
				var that = this
				addKaShangItem(this.addItem).then(res=>{
					if(res.data.data){
						that.addItem = {}
						that.list()
						ElNotification({
							title: '成功',
							message: '添加成功！',
							type: 'success',
						})
					}else{
						ElNotification({
							title: '失败',
							message: '添加失败！',
							type: 'error',
						})
					}
				})
			},
			
			show:function(data){
				var that = this
				getKaShangById(data.id).then(res=>{
					that.editItem = res.data.data
					this.editDialog = true
				})
			},
			
			edit:function(){
				var that = this
				updateKaShangItem(that.editItem).then(()=>{
					that.list()
					that.editDialog = false
				})
			},
			
			deleteItem:function(data){
				var that = this
				this.$confirm('确定删除该卡商？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					deleteKaShangItem({
						id:data.id
					}).then(res=>{
						if(res.data.data){
							that.$message({
								type: 'success',
								message: '删除成功!'
							});
							that.list();
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			}
		}


	}
</script>

<style scoped="scoped">
	.search-box {
		margin: 0;
		border: 1px solid #e6e6e6;
		padding: 10px 20px 20px 20px;
		color: #6b6b6b;
	}

	.page {
		background-color: white;
		padding: 10px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.device-info-card {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background-color: rgba(0, 0, 0, .5);
		z-index: 99;
	}

	.info-card {
		width: 800px;
		padding: 30px;
		position: absolute;
		top: 5vh;
		left: 50%;
		margin-left: -400px;
		transition: 1s;
		z-index: 2;
	}
</style>
